export const getData = (dispatch) => ({
	getData: (data) => {
		dispatch({type: "GET_DATA", payload: data})
	}
});

export const toggleSidebar = (dispatch) => ({
	toggleSidebar: (show) => {
		dispatch({type: "SIDEBAR_TOGGLE", payload: show})
	}
});

export const switchOrganization = (dispatch) => ({
	switchOrganization: (show) => {
		dispatch({type: "ORGANIZATION_SWITCH_CHANGE", payload: show})
	}
});

export const setRole = (dispatch) => ({
	setRole: (show) => {
		dispatch({type: "ROLE_CHANGE", payload: show})
	}
});

export const toggleRightSidebar = (dispatch) => ({
	toggleRightSidebar: (show) => {
		dispatch({type: "RIGHT_SIDEBAR_TOGGLE", payload: show})
	}
});

export const showCreativePreview = (dispatch) => ({
	showCreativePreview: (open) => {
		dispatch({type: "CREATIVE_PREVIEW", payload: open});
	}
});