import React from "react";
import { BrowserRouter, Switch, Route } from "react-router-dom";

import Header from "components/Header/Header";
import Mainpage from "components/pages/Mainpage/Mainpage";

function Routing() {
	return (
        <BrowserRouter>
            <Header/>
            <Switch>
                <Route exact path="/" component={Mainpage}/>
            </Switch>
        </BrowserRouter>
	);
}

export default Routing;
