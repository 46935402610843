import React, {Component} from "react";
import {Switch, Icon, ButtonCircle} from "factor";

import styles from "./FinancesList.module.scss";

class FinancesList extends Component {
	state = {
		listSwitch: false,
	}

	render() {
		const {
			className,
			list,
		} = this.props;

		const {
			listSwitch,
		} = this.state;

		return (
			<div className={`${styles.container} ${className || ""}`}>
				<header className={styles.header}>
					<Switch
						className={styles.switch}
						name="rightsidebar-finances-list"
						list={["Payments", "Invoices"]}
						value={listSwitch}
						onChange={(v) => this.setState({listSwitch: v})}
					/>
					<div className={styles.period}>
						Week
						<Icon name="Expand"/>
					</div>
				</header>
				<ul className={styles.list}>
					{list.map((i, k) => (
						<li className={styles.item} key={k}>
							<div className={`${styles.status} _green`}></div>
							<div className={styles.info}>
								<h4 className={styles.name}>
									{i.name}
								</h4>
								{i.id &&
									<span className={styles.id}>
										{i.id}
									</span>
								}
							</div>
							<div className={styles.sum}>
								$46,233
								{i.change &&
									<span className={styles.change}>
										{i.change}
									</span>
								}
							</div>
							<ButtonCircle outline iconName="More" className={`_size-16 ${styles.btnMore}`}/>
						</li>
					))}
				</ul>
			</div>
		)
	}
}



export default FinancesList;