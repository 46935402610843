import React, {Component} from "react";

import styles from "./FundsList.module.scss";

import Radio from "components/shared/Radio/Radio";

import {ReactComponent as BankIcon} from "img/bank.svg";
import {ReactComponent as CardIcon} from "img/card.svg";
const paypalImage = [
	require("img/paypal.png"),
	require("img/paypal@2x.png"),
	require("img/paypal@3x.png"),
];

const methods = [
	{
		icon: <BankIcon/>,
		title: "PNC Bank",
		info: <React.Fragment>
			<p>SPEND- EVERYDAY SPENDING</p>
			<p>CHECKING **** 1322</p>
		</React.Fragment>,
	},
	{
		icon: <CardIcon/>,
		title: "Mastercard ****3221",
		info: <React.Fragment>
			<p>Tom Smith</p>
			<p>Expires 3/22</p>
		</React.Fragment>,
	},
	{
		image: paypalImage,
		title: "Paypal",
		info: <p>@tsmith2100</p>,
	},
];

class FundsList extends Component {
	state = {
		methodIndex: 0,
	}

	render() {
		const {
			className = "",
		} = this.props;

		const {
			methodIndex,
		} = this.state;

		return (
			<div className={`${styles.list} ${className}`}>
				{methods.map((i, k) => {
					const title = (
						<div className={styles.radioLabel}>
							{i.icon &&
							<i>
								{i.icon}
							</i>
							}
							{i.image &&
							<div className={styles.radioImage}>
								<img
									src={i.image[0]}
									srcSet={i.image[1] &&
									(`${i.image[1]} 2x` + (i.image[2] && `, ${i.image[2]} 3x`))
									}
									alt=""
								/>
							</div>
							}
							<div className={styles.radioInfo}>
								<h4 className={styles.radioTitle}>
									{i.title}
								</h4>
								<div className={styles.radioDescription}>
									{i.info}
								</div>
							</div>
						</div>
					);

					return (
						<Radio
							key={i.title}
							className={styles.radio}
							name="payment method"
							id={i.title}
							checked={k === methodIndex}
							onChange={() => this.setState({methodIndex: k})}
							title={title}
						/>
					)
				})}
			</div>
		);
	}
}

export default FundsList;