import React, {Component} from "react";
import {TextField, Button, Icon} from "factor";

import styles from "./AddFunds.module.scss";

import AddPaymentMethod from "./AddPaymentMethod/AddPaymentMethod";
import FundsList from "./FundsList/FundsList";

class AddFunds extends Component {
	state = {
		amount: "$0.00",
		showingPopup: undefined,
		changePaymentMethod: false,
		addPaymentMethod: false,
	}

	render() {
		const {
			className = "",
			close,
			type,
		} = this.props;

		const {
			amount,
			changePaymentMethod,
			addPaymentMethod,
		} = this.state;

		return (
			<div className={`${styles.container} ${className}`}>
				{!addPaymentMethod ?
					<div className={styles.main}>
						<header className={styles.header}>
							<h4 className={`titl-4 mb-2 ${styles.title}`}>
								Add Funds
							</h4>
							<div className={styles.sum}>
								<div className={styles.sumValue}>
									$1,644.23
								</div>
								<div className={styles.sumCredit}>
									Credit $0.00
								</div>
							</div>
						</header>
						<div className={`d-flex ${type === "Add Credit" ? "flex-wrap" : ""} align-items-center mb-3`}>
							<div className={styles.amount}>
								<TextField
									label="Amount"
									value={amount}
									onChange={v => this.setState({amount: v})}
								/>
							</div>
							{type === "Add Payment" ?
								!changePaymentMethod ?
									<div className={styles.method}>
										<p>spend- everyday spending</p>
										<p>checking **** 1322</p>
										<button
											className={styles.methodBtn}
											onClick={() => this.setState({changePaymentMethod: true})}
										>
											change method
											<Icon name="Expand"/>
										</button>
									</div>
									:
									<Button
										className="btn-round _filled _conflower-blue"
										onClick={() => this.setState({addPaymentMethod: true})}
									>
										Add Payment Method
									</Button>
								:
								<div className="d-flex justify-content-end w-100 mt-2">
									<Button
										className="btn-round  _conflower-blue mr-3"
										onClick={close}
									>
										Cancel
									</Button>
									<Button className="btn-round _filled _conflower-blue">
										Pre-Approve
									</Button>
								</div>
							}
						</div>
						{changePaymentMethod &&
							<FundsList/>
						}
						<p className={`${styles.p}`}>
							Funds will take 2-3 Business Days to clear and become available for spending in your account.
						</p>
						{type === "Add Payment" &&
							<div className="d-flex justify-content-end mt-2">
								<Button
									className="btn-round _conflower-blue mr-3"
									onClick={close}
								>
									Cancel
								</Button>
								<Button
									className="btn-round _filled _conflower-blue"
									disabled={amount === ""}
								>
									Add Funds
								</Button>
							</div>
						}
					</div>
					:
					<AddPaymentMethod
						close={() => this.setState({addPaymentMethod: false})}
					/>
				}
			</div>
		);
	}
}

export default AddFunds;