import React from "react";
import {Button} from "factor";

import Main from "components/Main/Main";
import RightSidebar from "components/RightSidebar/RightSidebar";
import MainContent from "components/MainContent/MainContent";

import styles from "./Mainpage.module.scss";

const Mainpage = () => (
	<Main noSidebar>
		<MainContent>
			<div className="d-flex justify-content-end">
				Inventory and Finances Plugins
			</div>
		</MainContent>
		<RightSidebar/>
	</Main>
);

export default Mainpage;