import React, {Component} from "react";
import {Icon, Button, TextField, Tumbler} from "factor";

import styles from "./AddPaymentMethod.module.scss";

import {ReactComponent as BankIcon} from "img/bank.svg";
import {ReactComponent as CardIcon} from "img/card.svg";
const paypalImage = [
	require("img/paypal.png"),
	require("img/paypal@2x.png"),
	require("img/paypal@3x.png"),
];

const visaImage = [
	require("img/visa.png"),
	require("img/visa@2x.png"),
	require("img/visa@3x.png"),
];

const methods = [
	{
		icon: <BankIcon/>,
		title: "Bank",
	},
	{
		icon: <CardIcon/>,
		title: <React.Fragment>Credit <br/>Card</React.Fragment>,
	},
	{
		image: paypalImage,
		title: "Paypal",
	},
];

class AddPaymentMethod extends Component {
	state = {
		methodIndex: 1,
		cardholderName: "",
		cardNumber: "",
		expirationDate: "",
		cvv: "",
		saveForFuture: true,
	}

	render() {
		const {
			className = "",
			close,
		} = this.props;

		const {
			methodIndex,
			cardholderName,
			cardNumber,
			expirationDate,
			cvv,
			saveForFuture,
		} = this.state;

		return (
			<div className={`${styles.container} ${className}`}>
				<header className={`${styles.header} mb-3`}>
					<h4 className={`title-4 ${styles.title}`}>
						Add Payment Method
					</h4>
				</header>
				<div className={styles.methods}>
					<h4 className={`mb-3 ${styles.label}`}>
						Select your payment method
					</h4>
					<div className={styles.methodsList}>
						{methods.map((i, k) => (
							<div
								key={i.title}
								className={`${styles.method} ${methodIndex === k ? "_active" : ""}`}
								onClick={() => this.setState({methodIndex: k})}
							>
								{i.icon &&
									<i>
										{i.icon}
									</i>
								}
								{i.image &&
									<div className={styles.methodImage}>
										<img
											src={i.image[0]}
											srcSet={i.image[1] &&
											(`${i.image[1]} 2x` + (i.image[2] && `, ${i.image[2]} 3x`))
											}
											alt=""
										/>
									</div>
								}
								<h4 className={styles.methodTitle}>
									{i.title}
								</h4>
							</div>
						))}
					</div>
				</div>
				<h4 className={styles.label}>
					Select your payment method
				</h4>
				<div className="mb-3">
					<TextField
						label="Cardholder’s Name"
						value={cardholderName}
						onChange={v => this.setState({cardholderName: v})}
					/>
				</div>
				<div className={`mb-3 ${styles.cardNumber}`}>
					<TextField
						label="Card Number"
						value={cardNumber}
						onChange={v => this.setState({cardNumber: v})}
					/>
					<i>
						<img
							src={visaImage[0]}
							srcSet={visaImage[1] &&
								(`${visaImage[1]} 2x` + (visaImage[2] && `, ${visaImage[2]} 3x`))
							}
							alt=""
						/>
					</i>
				</div>
				<div className="row mb-3">
					<div className="col-6">
						<TextField
							label="Expiration Date"
							value={expirationDate}
							onChange={v => this.setState({expirationDate: v})}
						/>
					</div>
					<div className="col-6">
						<TextField
							label="CVV"
							value={cvv}
							onChange={v => this.setState({cvv: v})}
						/>
					</div>
				</div>
				<div className={`mb-4 ${styles.tumbler}`}>
					<h4 className={`mr-3 ${styles.label}`}>
						Save Payment Method for future use
					</h4>
					<Tumbler
						onOff={false}
						name="Save Payment Method for future use"
						id="Save Payment Method for future use"
						className={styles.saveForFuture}
						on={saveForFuture}
						onChange={(value) => this.setState({
							saveForFuture: !saveForFuture,
						})}
					/>
				</div>
				<div className="d-flex justify-content-end">
					<Button className="btn-round _conflower-blue mr-2" onClick={close}>
						Cancel
					</Button>
					<Button className="btn-round _filled _conflower-blue">
						Add Payment Method
					</Button>
				</div>
			</div>
		);
	}
}

export default AddPaymentMethod;