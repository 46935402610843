import React from "react";

import styles from "./RightSidebarHeader.module.scss";

const RightSidebarHeader = (props) => {
	const {
		className = "",
		close,
		title,
		children,
	} = props;

	return (
		<div className={`${styles.header} ${className}`}>
			<button
				className={`btn-close ${styles.close}`}
				onClick={close}
			></button>
			<h4 className={`title-4 ${styles.title}`}>
				{title}
			</h4>
			{children}
		</div>
	);
};

export default RightSidebarHeader;