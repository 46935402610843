import React from "react";

import Routing from "components/Routing/Routing";

function App() {
  return (
      <div className="page">
          <Routing/>
      </div>
  );
}

export default App;
