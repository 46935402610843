import React, {Component} from "react";
import {NavigationBar, ButtonCircle} from "factor";
import {withRouter, Link} from "react-router-dom";

import {ReactComponent as Logo} from "img/logo.svg";

import "./Header.scss";
import styles from "./Header.module.scss";

class Header extends Component {
	constructor() {
		super();
		this.navigationBar = React.createRef();
	}

	render() {
		const popupContent = <span>popup content</span>;

		return (
			<NavigationBar
				backButton={false}
				ref={this.navigationBar}
				leftLogo={<Link to="/" className="nav-bar__circle-icon" key="logo"><Logo/></Link>}
				title="Finances and Payment"
				popupContent={popupContent}
			>
				<ButtonCircle outline iconName="Apps" className="_size-16 ml-auto"/>
			</NavigationBar>
		)
	}
}

export default withRouter(Header);