import React, {Component} from "react";
import {Switch, TextFieldWithIcon, Button, Select, Icon} from "factor";

import styles from "./Inventory.module.scss";

import Sidebar from "components/SideBar/Sidebar";
import RightSidebarHeader from "components/RightSidebar/RightSidebarHeader/RightSidebarHeader";
import InventoryBarChart from "./InventoryBarChart/InventoryBarChart";
import InventoryNewGroup from "./InventoryNewGroup/InventoryNewGroup";

import {testOptions} from "components/consts";

const audienceAnalysisOptions = [ {label: "Geography", value: "Geography"}, ...testOptions ];

class Inventory extends Component {
	state = {
		inventorySwitch: false,
		publisherName: "",
		audienceAnalysis: audienceAnalysisOptions[0],
		newGroup: false,
	}

	render() {
		const {
			close,
		} = this.props;

		const {
			inventorySwitch,
			publisherName,
			audienceAnalysis,
			newGroup,
		} = this.state;

		return (
			<Sidebar
				className="_right-sidebar"
				contentClassName={styles.content}
				notFixed
				header={<RightSidebarHeader className={styles.rightSidebar} title="Inventory" close={close}>
					<Switch
						className={styles.switch}
						name="inventory-switch"
						list={["Open Exchange", "Private Deals"]}
						value={inventorySwitch}
						onChange={(v) => this.setState({inventorySwitch: v})}
					/>
				</RightSidebarHeader>}
			>
				{newGroup ?
					<InventoryNewGroup
						close={() => this.setState({newGroup: false})}
					/>
					:
					<React.Fragment>
						<header className={styles.header}>
							<div className={styles.publisherName}>
								<TextFieldWithIcon
									iconName="Search"
									label="Publisher name"
									name="publisher-name"
									id="publisher-name"
									onChange={(v) => this.setState({
										publisherName: v.target.value
									})}
									value={publisherName}
									placeholder="Publisher Name"
								/>
							</div>
							<Button
								className={`btn-round _filled _conflower-blue ${styles.headerBtn}`}
								onClick={() => this.setState({newGroup: true})}
							>
								New Group
							</Button>
						</header>
						<div className={styles.select}>
							<Select
								label="Audience Analysis"
								showControlLabel
								options={audienceAnalysisOptions}
								value={audienceAnalysis}
								onChange={v => this.setState({audienceAnalysis: v})}
							/>
						</div>
						<ul className={styles.list}>
							{[...new Array(3)].map((i, k) => (
								<li className={styles.item} key={k}>
									<InventoryBarChart/>
								</li>
							))}
						</ul>
					</React.Fragment>
				}
			</Sidebar>
		);
	}
}



export default Inventory;