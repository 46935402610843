import React, {Component} from "react";
import {connect} from "react-redux";

import styles from "./Sidebar.module.scss";

import {toggleSidebar} from "store/actions";

import {ReactComponent as ArrowLeftIcon} from "img/arrow-left.svg";

class Sidebar extends Component {

	componentDidMount() {
		if(!this.props.notFixed) {
			window.addEventListener("resize", this.toggleSidebarOnResize);
			this.toggleSidebarOnResize();
		}
	}

	componentWillUnmount() {
		if(!this.props.notFixed) {
			window.removeEventListener("resize", this.toggleSidebarOnResize);
		}
	}

	toggleSidebarOnResize = () => {
		if (this.windowWidth === undefined) {
			this.windowWidth = window.innerWidth;

			if (this.windowWidth < 1280) {
				this.toggleSidebar();
			} else {
				return;
			}
		} else {
			if (this.windowWidth < 1280) {
				this.windowWidth = window.innerWidth;
				return;
			} else {
				if (window.innerWidth < 1280) {
					this.windowWidth = window.innerWidth;
					this.toggleSidebar();
				}
			}
		}
	}

	toggleSidebar = (show) => {
		const {
			toggleSidebar,
			activeSidebar,
		} = this.props;

		toggleSidebar(!activeSidebar);
	}

	render() {
		const {
			className,
			contentClassName,
			notFixed,
			children,
			header,
			footer,
			lastChildren,
			activeSidebar,
			toggleSidebar,
			containerHidden,
		} = this.props;

		let containerClassNames = [styles.sidebar];
		if(notFixed) {
			containerClassNames.push("_not-fixed");
		} else if(!activeSidebar) {
			containerClassNames.push("_hidden");
		}

		if(className) {
			containerClassNames.push(className);
		}

		return (
			<div className={containerClassNames.join(" ")}>
				{!notFixed &&
					<button
						className={styles.arrow}
						onClick={() => toggleSidebar(!activeSidebar)}
					>
						<ArrowLeftIcon/>
					</button>
				}
				<div className={`${styles.container} ${containerHidden ? "_hidden" : ""}`}>
					{header &&
						<div className={styles.header}>
							{header}
						</div>
					}
					<div className={styles.main}>
						<div className={`${styles.content} ${contentClassName || ""}`}>
							{children}
						</div>
					</div>
					{footer &&
						<footer className={styles.footer}>
							{footer}
						</footer>
					}
				</div>
				{lastChildren}
			</div>
		);
	}
}

export default connect(
	state => ({activeSidebar: state.activeSidebar}),
	dispatch => toggleSidebar(dispatch),
)(Sidebar);