import React, {Component} from "react";
import {Icon, Select} from "factor";

import styles from "./Finances.module.scss";

import Sidebar from "components/SideBar/Sidebar";
import RightSidebarHeader from "components/RightSidebar/RightSidebarHeader/RightSidebarHeader";
import LineChart from "components/shared/LineChart/LineChart";
import FinancesList from "./FinancesList/FinancesList";
import PeriodTypes from "components/shared/PeriodTypes/PeriodTypes";
import AddFunds from "components/AddFunds/AddFunds";

import {lineData} from "components/consts";

const list = [
	{ name: "Re-Elect Kristie Weaver", },
	{ name: "Warren for District 1", },
	{ name: "Gilead \"Across America\"", },
	{ name: "Shine On", },
	{ name: "Re-Elect Kristie Weaver", },
	{ name: "Warren for District 1", },
	{ name: "Gilead \"Across America\"", },
	{ name: "Shine On", },
];

const paymentTypeOptions = [
	{
		label: 'Default', isGroupHeader: true, options: [
			{
				value: 'Add Payment',
				label: <div className={styles.paymentTypeSelectOption}>
					Add Payment <Icon name="ArrowRight"/>
				</div>,
			},
		],
	},
	{
		label: 'Other Options', isGroupHeader: true, options: [
			{
				value: 'Add Credit',
				label: <div className={styles.paymentTypeSelectOption}>
					Add Credit <Icon name="ArrowRight"/>
				</div>,
			},
			{
				value: 'Invoice',
				label: <div className={styles.paymentTypeSelectOption}>
					Invoice <Icon name="ArrowRight"/>
				</div>,
			},
			{
				value: 'Refund',
				label: <div className={styles.paymentTypeSelectOption}>
					Refund <Icon name="ArrowRight"/>
				</div>,
			},
		],
	}
];

class Finances extends Component {
	state = {
		paymentType: null,
	}

	render() {
		const {
			close,
		} = this.props;

		const {
			paymentType
		} = this.state;

		return (
			<Sidebar
				className="_right-sidebar"
				contentClassName={styles.content}
				notFixed
				header={<RightSidebarHeader title="Finances" close={close}/>}
			>
				<div className={styles.chartBlock}>
					<div className={`${styles.funds} _plugins`}>
						<h4 className={styles.fundsTitle}>
							Funds $601,232.55
						</h4>
						<div className={styles.credit}>
							<div className={styles.creditAvailable}>
								available credit <span className="ml-2">$55,000.00</span>
							</div>
						</div>
						<div className="mt-2">
							<Select
								placeholder="Add Payment"
								options={paymentTypeOptions}
								value={paymentType}
								onChange={(v) => this.setState({paymentType: v})}
							/>
						</div>
					</div>
					<p className={styles.chartWarning}>
						You will run out of funds in 16 hours at the current rate of spending.
					</p>
					<PeriodTypes list={["Day", "Week", "Month", "Year"]}/>
					<div className={styles.chart}>
						<LineChart
							className="_finances"
							data={lineData}
							options={{
								margin: {top: 10, right: 16, bottom: 36, left: 32},
							}}
						/>
					</div>
				</div>
				<FinancesList className={styles.list} list={list}/>

				{paymentType && (paymentType.value === "Add Payment" || paymentType.value === "Add Credit") &&
					<AddFunds
						type={paymentType.value}
						className={styles.addFunds}
						close={() => this.setState({paymentType: null})}
					/>
				}
			</Sidebar>
		);
	}
}

export default Finances;