import React from "react";
import {connect} from "react-redux";

import styles from "./Main.module.scss";

const Main = props => (
	<div className={`${styles.main} ${(props.activeSidebar && !props.noSidebar) ? "_opened" : ""}`}>
		{props.children}
	</div>
);

export default connect(
	state => ({activeSidebar: state.activeSidebar})
)(Main);