import React, {Component} from "react";
import {Icon, TextFieldWithIcon, TextField, Select, Button} from "factor";

import styles from "./InventoryNewGroup.module.scss";

import BarChart from "components/shared/BarChart/BarChart";

import {selects, testOptions} from "components/consts";

const selectObjects = {};
selects.forEach(select => selectObjects[select.name] = { value: 0, label: "Option 1" });

const dimensions = [ {label: "Impressions", value: "Impressions" }, ...testOptions ];

console.log(dimensions);

class InventoryNewGroup extends Component {
	state = {
		publisherName: "",
		selectsStates: selectObjects,
		dimension: dimensions[0],
		groupName: "",
	}

	render() {
		const {
			className,
			close,
		} = this.props;

		const {
			publisherName,
			selectsStates,
			dimension,
			groupName,
		} = this.state;

		return (
			<div className={`${styles.container} ${className || ""}`}>
				<div className={styles.up}>
					<div className={styles.header}>
						<button className={`btn-back ${styles.back}`} onClick={close}>
							<Icon name="Left"/>
						</button>
						<h4 className={`title-4 ${styles.title}`}>
							Create New Group
						</h4>
					</div>
					<div className={styles.publisherName}>
						<TextFieldWithIcon
							iconName="Search"
							label="Publisher name"
							name="publisher-name"
							id="publisher-name"
							onChange={(v) => this.setState({
								publisherName: v.target.value
							})}
							value={publisherName}
							placeholder="Publisher Name"
						/>
					</div>
					{selects.map((select, i) => {
						if(i > 7) return;
						return (
							<div className="mb-3" key={selects[i].name}>
								<Select
									options={testOptions}
									onChange={(v) => this.setState({
										selectsStates: {
											...selectsStates,
											[selects[i].name]: v
										}
									})}
									placeholder={selects[i].label}
									value={selectsStates[i]}
									id={selects[i].name}
									name={selects[i].name}
									allSelectable
									isMulti
								/>
							</div>
						)
					})}
				</div>
				<div className={styles.overview}>
					<header className={styles.overviewHeader}>
						<h4 className={styles.overviewTitle}>
							Group Overview
						</h4>
						<ul className={styles.stats}>
							{[ {title: "Publishers", value: "2322" },{title: "Unique Reach", value: "474,232" }, ].map(i => (
								<li className={styles.statsItem} key={i.title}>
									<h4>
										{i.title}
									</h4>
									<span>
										{i.value}
									</span>
								</li>
							))}
						</ul>
					</header>
					<div className={styles.overviewSelect}>
						<Select
							value={dimension}
							options={dimensions}
							onChange={v => this.setState({dimension: v})}
						/>
					</div>
					<BarChart className={styles.barChart}/>
				</div>
				<footer className={styles.footer}>
					<TextField
						label="Group Name"
						value={groupName}
						onChange={v => this.setState({groupName: v})}
					/>
					<div className={styles.buttons}>
						<Button className="btn-round _conflower-blue">
							Cancel
						</Button>
						<Button className="btn-round _filled _conflower-blue">
							Create Group
						</Button>
					</div>
				</footer>
			</div>
		)
	}
}



export default InventoryNewGroup;