import React, {Component} from "react";

import styles from "./PeriodTypes.module.scss";

class PeriodTypes extends Component {
	state = {
		type: "Week",
	}

	render() {
		const {
			list = ["Day", "Week", "Month", "Year", "Custom"],
		} = this.props;

		const {
			type,
		} = this.state;

		return (
			<div className={styles.types}>
				{list.map(i => (
					<button
						key={i}
						className={`${styles.type} ${i === type ? "_active" : ""}`}
						onClick={() => this.setState({type: i})}
					>
						{i}
					</button>
				))}
			</div>
		)
	}
}

export default PeriodTypes;