import React from "react";
import {ResponsiveBar} from "@nivo/bar";
import {Button, ButtonCircle} from "factor";

import styles from "./InventoryBarChart.module.scss";

const barData2 = [
	{
		"device": "USA",
		"impressions": 22342,
		"color": "#A5D6A7",
	},
	{
		"device": "India",
		"impressions": 8342,
		"color": "#9FCAFF",
	},
	{
		"device": "England",
		"impressions": 8642,
		"color": "#6094FF",
	},
	{
		"device": "Argentina",
		"impressions": 4642,
		"color": "#5874DB",
	},
	{
		"device": "Other",
		"impressions": 3221,
		"color": "#4CAF50",
	},
];

const InventoryBarChart = props => {

	const customBarComponent = ({ x, y, width, height, color, data, ...rest }) => {
		const customHeight = 18;
		return <g transform={`translate(${x}, ${y + (height - customHeight) / 2})`}>
			<rect height={customHeight} width={width} rx={3} ry={3} fill={color} strokeWidth="0"/>
			<text x={width + 30} y={customHeight / 2} textAnchor="middle" dominantBaseline="central" style={{
				fontFamily: "Lato Regular, sans-serif",
				fontSize: "12px",
				lineHeight: "15px",
				fill: "#898989",
			}}>{data.value}</text>
		</g>
	};

	//for gradients
	let values = barData2.map((item, i) => item.impressions);
	let maxValue = Math.max(...values);

	return (
		<div className="block">
			<header className={styles.header}>
				<div className={styles.headerRow}>
					<h4 className={styles.headerTitle}>
						Inventory Group 1
					</h4>
					<Button className={`btn-round _filled _conflower-blue ${styles.headerAdd}`}>
						Add
					</Button>
					<ButtonCircle outline iconName="More" className={`_size-20 ${styles.headerMore}`}/>
				</div>
				<div className={`${styles.headerRow} justify-content-between`}>
					<span className={styles.headerText}>
						146 Publishers
					</span>
					<span className={styles.headerText}>
						Last used 08/12/2019
					</span>
				</div>
			</header>
			<div className={styles.barChart}>
				<ResponsiveBar
					data={JSON.parse(JSON.stringify(barData2)).reverse()}
					layout="horizontal"
					keys={["impressions"]}
					indexBy="device"
					barComponent={customBarComponent}
					isInteractive={true}
					margin={{
						top: 0,
						right: 55,
						bottom: 0,
						left: 65
					}}
					padding={0}
					colorBy="index"
					colors={d => `hsl(165, 65%, ${(1 - d.data.impressions / maxValue) * 25 + 25}%)`}
					theme={{
						axis: {
							ticks: {
								text: {
									fill: "#000000",
									fontFamily: "Lato Regular, sans-serif",
									fontSize: 12,
								},
							},
						},
					}}
					axisBottom={{
						tickSize: 0,
						format: () => {}
					}}
					axisLeft={{
						tickSize: 0,
						tickPadding: 12,
					}}
					enableGridX={false}
					enableGridY={false}
				/>
			</div>
		</div>
	);
};

export default InventoryBarChart;