// for creativesList
import image1 from "img/example/html/html-1.jpg";
import image2 from "img/example/html/html-2.jpg";
import image3 from "img/example/html/html-3.jpg";

const tableData = {
	unsortedData: {
		total: true,
		id: "total",
		activeCampaigns: "86",
		userBudget: "$95,993",
		userSpent: "$24,807",
		rowClassName: "_aggregated",
	},
	data: [
		{
			customer: "CADNC",
			dateAdded: "10/11/2019",
			assignees: "1",
			activeCampaigns: "86",
			userBudget: "$95,993",
			userSpent: "$24,807",
		},
		{
			customer: "Isabelle Brock",
			dateAdded: "09/24/2019",
			assignees: "2",
			activeCampaigns: "7",
			userBudget: "$61,535",
			userSpent: "$38,916",
		},
		{
			customer: "Lee Rogers",
			dateAdded: "01/16/2019",
			assignees: "2",
			activeCampaigns: "13",
			userBudget: "$32,912",
			userSpent: "$30,697",
		},
		{
			customer: "Mabel Watson",
			dateAdded: "05/14/2019",
			assignees: "3",
			activeCampaigns: "84",
			userBudget: "$88,213",
			userSpent: "$31,056",
		},
		{
			customer: "Isabelle McKinney",
			dateAdded: "02/12/2019",
			assignees: "1",
			activeCampaigns: "65",
			userBudget: "$86,559",
			userSpent: "$32,429",
		},
		{
			customer: "Jim Munoz",
			dateAdded: "01/08/2019",
			assignees: "3",
			activeCampaigns: "89",
			userBudget: "$92,941",
			userSpent: "$88,605",
		},
		{
			customer: "Joe Jensen",
			dateAdded: "10/13/2019",
			assignees: "3",
			activeCampaigns: "6",
			userBudget: "$56,707",
			userSpent: "$7,463",
		},
		{
			customer: "Mae Howard",
			dateAdded: "08/30/2019",
			assignees: "1",
			activeCampaigns: "23",
			userBudget: "$77,515",
			userSpent: "$18,772",
		},
	]
};

tableData.data.forEach((item, i) => {
	item.id = i < 10 ? `0${i}` : i;
});

export {tableData};

export const devices = ["Members", "Roles"];

export const eventsList = [
	{
		weekDay: "today",
		times: [
			{
				time: "9:30 AM",
				text: ["raised", { text: "Campaign 1", url: "#"}, "Budget to $2000 (+20%)"],
				app: { title: "Dashboard", url: "#" },
			},
			{
				time: "9:05 AM",
				text: ["created", { text: "TEST_1", url: "#"}, "inventory group"],
				app: { title: "Inventory", url: "#" },
			},
			{
				time: "6:05 AM",
				text: ["created", { text: "New Campaign", url: "#"}, "(pending)"],
				app: { title: "Dashboard", url: "#" },
			},
		],
	},
	{
		weekDay: "Mon",
		times: [
			{
				time: "9:30 AM",
				text: ["raised", { text: "Campaign 1", url: "#"}, "Budget to $2000 (+20%)"],
				app: { title: "Dashboard", url: "#" },
			},
			{
				time: "9:05 AM",
				text: ["created", { text: "TEST_1", url: "#"}, "inventory group"],
				app: { title: "Inventory", url: "#" },
			},
			{
				time: "6:05 AM",
				text: ["created", { text: "New Campaign", url: "#"}, "(pending)"],
				app: { title: "Dashboard", url: "#" },
			},
			{
				time: "9:30 AM",
				text: ["raised", { text: "Campaign 1", url: "#"}, "Budget to $2000 (+20%)"],
				app: { title: "Dashboard", url: "#" },
			},
			{
				time: "9:05 AM",
				text: ["created", { text: "TEST_1", url: "#"}, "inventory group"],
				app: { title: "Inventory", url: "#" },
			},
			{
				time: "6:05 AM",
				text: ["created", { text: "New Campaign", url: "#"}, "(pending)"],
				app: { title: "Dashboard", url: "#" },
			},
		],
	},
	{
		weekDay: "Sun",
		times: [
			{
				time: "9:30 AM",
				text: ["raised", { text: "Campaign 1", url: "#"}, "Budget to $2000 (+20%)"],
				app: { title: "Dashboard", url: "#" },
			},
			{
				time: "9:05 AM",
				text: ["created", { text: "TEST_1", url: "#"}, "inventory group"],
				app: { title: "Inventory", url: "#" },
			},
		],
	},
	{
		weekDay: "Sat",
		times: [
			{
				time: "9:30 AM",
				text: ["raised", { text: "Campaign 1", url: "#"}, "Budget to $2000 (+20%)"],
				app: { title: "Dashboard", url: "#" },
			},
			{
				time: "9:05 AM",
				text: ["created", { text: "TEST_1", url: "#"}, "inventory group"],
				app: { title: "Inventory", url: "#" },
			},
			{
				time: "6:05 AM",
				text: ["created", { text: "New Campaign", url: "#"}, "(pending)"],
				app: { title: "Dashboard", url: "#" },
			},
		],
	},
];

export const testOptions = [
	{label: "Option 1", value: "Option 1"},
	{label: "Option 2", value: "Option 2"},
	{label: "Option 3", value: "Option 3"},
];

export const creativesList = [
	{
		id: "001",
		checked: false,
		image: image1,
		title: "Violet Banner",
		uploaded: "25 August 2018",
		size: "250x250",
		status: "rejected",
	},
	{
		id: "002",
		checked: false,
		image: image2,
		title: "Violet Banner",
		uploaded: "25 August 2018",
		size: "250x250",
		status: "pending",
	},
	{
		id: "003",
		checked: false,
		image: image3,
		title: "Violet Banner",
		uploaded: "25 August 2018",
		size: "250x250",
		status: "approved",
	},
	{
		id: "004",
		checked: false,
		image: image1,
		title: "Violet Banner",
		uploaded: "25 August 2018",
		size: "250x250",
		status: "rejected",
	},
];

export const moreActions = [
	{
		icon: "Copy",
		title: "Duplicate",
	},
	{
		icon: "Edit",
		title: "Edit",
	},
	{
		icon: "Delete",
		title: "Delete",
	},
];

export const totalMoreActions = [
	{
		icon: "Copy",
		title: "Duplicate",
	},
	{
		icon: "Edit",
		title: "Rename",
	},
	{
		icon: "Delete",
		title: "Delete",
	},
];

export const lineData = [
	{
		"id": "donation",
		"color": "#28bc97",
		"data": [
			{
				"x": "06/14",
				"y": 2
			},
			{
				"x": "06/21",
				"y": 5
			},
			{
				"x": "06/24",
				"y": 16
			},
			{
				"x": "07/05",
				"y": 19
			},
		]
	}
];

export const timeZones = [
	{ label: "America/New_York", value: "America/New_York" },
	{ label: "US/Alaska", value: "US/Alaska" },
	{ label: "US/Arizona", value: "US/Arizona" },
	{ label: "US/Central", value: "US/Central" },
	{ label: "US/Eastern", value: "US/Eastern" },
	{ label: "US/Mountain", value: "US/Mountain" },
	{ label: "US/Pacific", value: "US/Pacific" },
	{ label: "UTC", value: "UTC" },
	{ label: "Africa/Abidjan", value: "Africa/Abidjan" },
	{ label: "Africa/Accra", value: "Africa/Accra" },
];

export const selects = [
	{
		label: "Source",
		name: "source"
	},
	{
		label: "iAB Category",
		name: "iABCategory"
	},
	{
		label: "Language",
		name: "language"
	},
	{
		label: "Creative Type",
		name: "creativeType"
	},
	{
		label: "Traffic Type",
		name: "trafficType"
	},
	{
		label: "Device Type",
		name: "deviceType"
	},
	{
		label: "OS",
		name: "OS"
	},
	{
		label: "Creative Size",
		name: "creativeSize"
	},
	{
		label: "Country",
		name: "country"
	},
	{
		label: "States",
		name: "states"
	},
];