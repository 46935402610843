import React from "react";
import { ResponsiveLine } from "@nivo/line";

import styles from "./LineChart.module.scss";

const LineChart = props => {
	const {
		data,
		className,
		options,
	} = props;

	return (
		<div className={`${styles.chart} ${className || ""}`}>
			<ResponsiveLine
				data={data}
				enablePoints={true}
				pointSize={0}
				pointBorderWidth={0}
				enableGridX={false}
				enableGridY={true}
				colors={{ datum: "color" }}
				lineWidth={3}
				margin={{top: 10, right: 20, bottom: 36, left: 35}}
				xScale={{type: "point"}}
				yScale={{type: "linear", stacked: true, min: 0, max: "auto"}}
				axisTop={null}
				axisRight={null}
				axisLeft={{
					tickSize: 0,
					tickPadding: 8,
					format: value => `$${value}k`,
				}}
				axisBottom={{
					tickSize: 0,
					tickPadding: 20,
				}}
				{...options}
			/>
		</div>
	);
};

export default LineChart;