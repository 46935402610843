import React from "react";
import {connect} from "react-redux";

import styles from "./MainContent.module.scss"

const MainContent = (props) => {
	const {
		children,
		rightSidebarOpened,
	} = props;

	return (
		<section className={`${styles.container} ${rightSidebarOpened ? "_right-sidebar-opened" : ""}`}>
			{children}
		</section>
	);
};

export default connect(
	state => ({
		rightSidebarOpened: state.rightSidebarOpened,
	})
)(MainContent);